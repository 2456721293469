<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import { useWorldShoppingDayModalWindow } from '~/composables/world-shopping-day-modal-window'
import Page from '~/enums/page'

const model = defineModel<boolean>()

const imagePath = '/images/banners/83_mw.jpg'
const categoryPath = `${Page.Categories}1111-vsemirnyy-den-rasprodazh-388/`
const title = 'Продлили распродажу 11.11 до\xA0конца недели'

const router = useRouter()

const { makeWorldShoppingDayModalWindowClosed } = useWorldShoppingDayModalWindow()

function close (): void {
  model.value = false
}

function closed (): void {
  makeWorldShoppingDayModalWindowClosed()
}

async function openCategory (): Promise<void> {
  await router.push(categoryPath)
}
</script>

<template>
  <CustomModalWindow v-model="model" size="large" @closed="closed">
    <template #title>
      {{ title }}
    </template>

    <div class="relative h-0 cursor-pointer pb-[56.25%]" @click="openCategory(); close()">
      <img
        v-lazy="imagePath"
        :alt="title"
        class="absolute size-full min-h-full rounded-xl object-contain"
        loading="lazy"
      >
    </div>

    <template #action>
      <BasicButton color="green" :title="'За\xA0покупками'" @click="openCategory(); close()" />
    </template>
  </CustomModalWindow>
</template>
