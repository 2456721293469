<script lang="ts" setup>
import NewCatalogMenuBody from '~/components/new-catalog-menu/NewCatalogMenuBody.vue'
import NewCatalogMenuPrinter from '~/components/new-catalog-menu/NewCatalogMenuPrinter.vue'
import NewCatalogMenuSidebar from '~/components/new-catalog-menu/NewCatalogMenuSidebar.vue'
import NewCatalogMenuTitle from '~/components/new-catalog-menu/NewCatalogMenuTitle.vue'
import NewCatalogMenuWrapper from '~/components/new-catalog-menu/NewCatalogMenuWrapper.vue'
import catalogMenuItems from '~/constants/catalog-menu-items'

const sidebarRef = ref()

const activeItem = computed(() => catalogMenuItems[sidebarRef.value?.activeItemIndex])
const title = computed(() => activeItem.value?.name)
const items = computed(() => activeItem.value?.items ?? [])
const itemsForColumn1 = computed(() => items.value.slice(0, items.value.length / 2))
const itemsForColumn2 = computed(() => items.value.slice(items.value.length / 2))
</script>

<template>
  <div class="new-catalog-menu-overlay">
    <NewCatalogMenuWrapper>
      <div class="row">
        <NewCatalogMenuSidebar ref="sidebarRef" :items="catalogMenuItems" />
        <NewCatalogMenuBody>
          <NewCatalogMenuTitle>
            {{ title }}
          </NewCatalogMenuTitle>

          <div class="row">
            <div class="column-6 tb12">
              <NewCatalogMenuPrinter :data="itemsForColumn1" />
            </div>
            <div class="column-6 tb12">
              <NewCatalogMenuPrinter :data="itemsForColumn2" />
            </div>
          </div>
        </NewCatalogMenuBody>
      </div>
    </NewCatalogMenuWrapper>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

.new-catalog-menu-overlay {
  background: rgba(variables.$main-gray-color, 75%);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 61px;
  width: 100%;
}
</style>
