<template>
  <div class="new-catalog-menu-subtitle">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.new-catalog-menu-subtitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}
</style>
