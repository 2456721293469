<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useModal } from 'vue-final-modal'

import ContactUs from '~/components/modal-windows/ContactUs.vue'
import Page from '~/enums/page'
import helpers from '~/helpers'
import { useAuthStore } from '~/store/auth'
import { useProductsStore } from '~/store/products'
import { useUserStore } from '~/store/user'
import type { HeaderExtraMenuItem, SignInMenuItem } from '~/types/menus'

const route = useRoute()
const authStore = useAuthStore()
const productsStore = useProductsStore()
const userStore = useUserStore()

const { hasAccessToAdminPanel, isSpecialist, isSupplier } = storeToRefs(userStore)

const props = withDefaults(
  defineProps<{
    isMobile?: boolean
    isMobileMenuOpen?: boolean
    isSearchMenuOpen?: boolean
    position?: 'left' | 'right'
  }>(),
  { isMobile: false, isMobileMenuOpen: false, isSearchMenuOpen: false, position: 'left' }
)

const emit = defineEmits<{
  (e: 'open', path: string, isAuthRequired: boolean): void
  (e: 'toggleMobileMenu', isMobileMenuOpen: boolean): void
  (e: 'toggleSearchMenu', isSearchMenuOpen: boolean): void
}>()

const currentRoutePath = ref(route.path)

const signInMenuItems = computed(() => {
  const signInMenuItems: SignInMenuItem[] = []

  signInMenuItems.push({
    isAuthRequired: true,
    name: authStore.isUser ? 'ЛК клиента' : 'Вход/регистрация',
    path: Page.Account
  })

  if (authStore.isUser) {
    if (isSpecialist.value) {
      signInMenuItems.push({ isAuthRequired: true, name: 'ЛК специалиста', path: Page.Specialist })
    }

    if (isSupplier.value) {
      signInMenuItems.push({ isAuthRequired: true, name: 'ЛК поставщика', path: Page.Supplier })
    }

    if (hasAccessToAdminPanel.value) {
      signInMenuItems.push({ isAuthRequired: true, name: 'Админпанель', path: Page.Admin })
    }
  }

  return signInMenuItems
})
const badgeTextForCart = computed(() => {
  const { cartTotalItems } = productsStore

  return cartTotalItems === 0 ? null : helpers.formatBadgeText(cartTotalItems)
})
const badgeTextForWishList = computed(() => {
  const { wishListTotalItems } = productsStore

  return wishListTotalItems === 0 ? null : helpers.formatBadgeText(wishListTotalItems)
})
const items = computed(() => [
  {
    badgeText: badgeTextForCart.value,
    icon: 'outlined/shopping-cart',
    isAuthRequired: false,
    path: Page.Cart
  },
  {
    badgeText: badgeTextForWishList.value,
    icon: 'outlined/favorite-border',
    isAuthRequired: false,
    path: Page.Favorites
  }
] as HeaderExtraMenuItem[])

watch(
  () => route.path,
  (x: string): void => {
    currentRoutePath.value = x
  }
)

async function openContactUsModalWindow (attrs = {}): Promise<void> {
  await useModal({ component: ContactUs, attrs }).open()
}
</script>

<template>
  <div class="header-extra-menu" :class="[{ mobile: isMobile, usual: !isMobile }, position]">
    <template v-if="isMobile">
      <template v-if="position === 'left'">
        <ButtonWithBadge
          class="menu-item"
          :icon-name="isMobileMenuOpen ? 'outlined/close' : 'outlined/menu'"
          @click="emit('toggleMobileMenu', !isMobileMenuOpen)"
        />
        <ButtonWithBadge
          class="menu-item"
          icon-name="filled/phone"
          @click="openContactUsModalWindow({ isCallUs: true })"
        />
      </template>
      <template v-else-if="position === 'right'">
        <ButtonWithBadge
          class="menu-item"
          icon-name="outlined/search"
          @click="emit('toggleSearchMenu', !isSearchMenuOpen)"
        />
        <ButtonWithBadge
          class="menu-item"
          :icon-class="{ active: currentRoutePath === Page.Cart }"
          icon-name="outlined/shopping-cart"
          :text="badgeTextForCart"
          @click="emit('open', Page.Cart, false)"
        />
      </template>
    </template>
    <template v-else>
      <div class="call-us cursor-pointer whitespace-nowrap">
        <span @click="openContactUsModalWindow({ isCallUs: true })">Позвонить нам</span>
      </div>

      <ButtonWithBadge
        v-for="x in items"
        :key="x.path"
        class="menu-item"
        :icon-class="{ active: x.path === currentRoutePath }"
        :icon-name="x.icon"
        :text="x.badgeText"
        @click="emit('open', x.path, x.isAuthRequired)"
      />

      <div class="sign-in-button">
        <DropdownMenu v-if="signInMenuItems.length > 1" icon-name="outlined/account-circle">
          <DropdownMenuItem
            v-for="x in signInMenuItems"
            :key="x.path"
            class="more-menu-item whitespace-nowrap"
            @click="emit('open', x.path, x.isAuthRequired)"
          >
            {{ x.name }}
          </DropdownMenuItem>
        </DropdownMenu>
        <SvgIcon
          v-else
          :class="{ active: signInMenuItems[0].path === currentRoutePath }"
          name="outlined/account-circle"
          @click="emit('open', signInMenuItems[0].path, signInMenuItems[0].isAuthRequired)"
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

.header-extra-menu {
  &.usual {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    @media screen and (max-width: 1070px) {
      display: none;
    }

    &.left {
      margin-left: 24px;
    }

    .call-us {
      color: variables.$main-green-color;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      margin-left: 8px;
    }
  }

  &.mobile {
    display: none;

    @media screen and (max-width: 1070px) {
      display: flex;
    }

    &.right {
      justify-content: flex-end;
    }
  }

  .sign-in-button {
    margin-left: 24px;

    svg {
      font-size: 24px;
      cursor: pointer;

      &:hover {
        color: variables.$main-green-color;
      }
    }

    .more-menu-item {
      font-size: 18px;
    }
  }

  .menu-item {
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
