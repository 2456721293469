import type { NewCategoryMenuItem } from '~/types/menus'

export default [
  {
    name: 'БАД',
    categoryId: '80',
    categorySlug: 'bad',
    items: [
      {
        name: 'Иммуностимуляторы',
        categoryId: '7',
        categorySlug: 'immunostimulyatory-immunomodulyatory',
        items: []
      },
      {
        name: 'Похудение',
        categoryId: '118',
        categorySlug: 'snizhenie-vesa',
        items: []
      },
      {
        name: 'Пептиды',
        categoryId: '227',
        categorySlug: 'peptidy',
        items: []
      },
      {
        name: 'Аминокислоты',
        categoryId: '30',
        categorySlug: 'aminokisloty',
        items: [
          {
            name: 'L-теанин',
            categoryId: '230',
            categorySlug: 'l-teanin'
          },
          {
            name: 'BCAA',
            categoryId: '231',
            categorySlug: 'bcaa'
          },
          {
            name: 'L-аргинин',
            categoryId: '232',
            categorySlug: 'l-arginin'
          },
          {
            name: 'L-глютамин',
            categoryId: '233',
            categorySlug: 'l-glyutamin'
          },
          {
            name: 'L-глицин',
            categoryId: '234',
            categorySlug: 'l-glitsin'
          },
          {
            name: 'L-лизин',
            categoryId: '235',
            categorySlug: 'l-lizin'
          },
          {
            name: 'L-карнитин',
            categoryId: '73',
            categorySlug: 'l-karnitin'
          },
          {
            name: 'L-тирозин',
            categoryId: '236',
            categorySlug: 'l-tirozin'
          },
          {
            name: 'L-триптофан',
            categoryId: '364',
            categorySlug: 'l-triptofan'
          },
          {
            name: '5-HTP',
            categoryId: '363',
            categorySlug: '5-htp'
          },
          {
            name: 'Аминокислотные комплексы',
            categoryId: '237',
            categorySlug: 'aminokislotnye-kompleksy'
          },
          {
            name: 'Таурин',
            categoryId: '337',
            categorySlug: 'taurin'
          }
        ]
      },
      {
        name: 'Антиоксиданты',
        categoryId: '9',
        categorySlug: 'antioksidanty',
        items: [
          {
            name: 'Селен',
            categoryId: '60',
            categorySlug: 'selen'
          },
          {
            name: 'Витамин E',
            categoryId: '5',
            categorySlug: 'vitamin-e'
          },
          {
            name: 'Витамин C',
            categoryId: '4',
            categorySlug: 'vitamin-c'
          },
          {
            name: 'Куркумин',
            categoryId: '23',
            categorySlug: 'kurkumin'
          },
          {
            name: 'Лютеин, зеаксантин',
            categoryId: '338',
            categorySlug: 'lyutein-zeaksantin'
          },
          {
            name: 'Коэнзим Q10',
            categoryId: '71',
            categorySlug: 'koenzim-q10'
          },
          {
            name: 'Альфа-липоевая кислота',
            categoryId: '238',
            categorySlug: 'alfa-lipoevaya-kislota'
          },
          {
            name: 'Глутатион',
            categoryId: '211',
            categorySlug: 'glutation'
          },
          {
            name: 'Карнозин',
            categoryId: '239',
            categorySlug: 'karnozin'
          },
          {
            name: 'Ресвератрол',
            categoryId: '240',
            categorySlug: 'resveratrol'
          },
          {
            name: 'Спирулина',
            categoryId: '22',
            categorySlug: 'spirulina'
          },
          {
            name: 'Хлорелла',
            categoryId: '17',
            categorySlug: 'hlorella'
          }
          // {
          //   name: 'Чага',
          //   categoryId: null,
          //   categorySlug: null
          // }
        ]
      },
      {
        name: 'Кости и суставы',
        categoryId: '114',
        categorySlug: 'kosti-i-sustavy',
        items: [
          {
            name: 'MSM',
            categoryId: '161',
            categorySlug: 'msm-metilsulfonilmetan'
          },
          {
            name: 'Глюкозамин и хондроитин',
            categoryId: '160',
            categorySlug: 'hondroitin-i-glyukozamin'
          },
          {
            name: 'Коллаген',
            categoryId: '8',
            categorySlug: 'kollagen'
          },
          {
            name: 'Кальций',
            categoryId: '178',
            categorySlug: 'kaltsiy'
          }
        ]
      },
      {
        name: 'Рыбий жир и омега',
        categoryId: '78',
        categorySlug: 'rybiy-zhir-i-omega',
        items: [
          {
            name: 'Омега-3',
            categoryId: '365',
            categorySlug: 'omega-3'
          },
          {
            name: 'Омега-3,6,9',
            categoryId: '13',
            categorySlug: 'omega-369'
          },
          {
            name: 'DHA (ДГК)',
            categoryId: '339',
            categorySlug: 'dha-dgk'
          },
          {
            name: 'Льняное масло',
            categoryId: '340',
            categorySlug: 'lnyanoe-maslo'
          },
          {
            name: 'Масло криля',
            categoryId: '341',
            categorySlug: 'maslo-krilya'
          },
          {
            name: 'Масло примулы вечерней',
            categoryId: '164',
            categorySlug: 'primula-vechernyaya'
          },
          {
            name: 'Масло черного тмина',
            categoryId: '241',
            categorySlug: 'maslo-chernogo-tmina'
          },
          {
            name: 'Рыбий жир',
            categoryId: '88',
            categorySlug: 'rybiy-zhir'
          }
        ]
      },
      {
        name: 'Для кожи, волос и ногтей',
        categoryId: '113',
        categorySlug: 'volosy-nogti-i-kozha',
        items: [
          {
            name: 'Коллаген',
            categoryId: '8',
            categorySlug: 'kollagen'
          },
          {
            name: 'Гиалуроновая кислота',
            categoryId: '152',
            categorySlug: 'gialuronovaya-kislota'
          },
          {
            name: 'Биотин (B7)',
            categoryId: '242',
            categorySlug: 'biotin-b7'
          },
          {
            name: 'Инозитол',
            categoryId: '343',
            categorySlug: 'inozitol'
          }
        ]
      },
      {
        name: 'Мультикомплексы',
        categoryId: '177',
        categorySlug: 'multikompleksy',
        items: [
          {
            name: 'Витаминные комплексы',
            categoryId: '361',
            categorySlug: 'vitaminnye-kompleksy'
          },
          {
            name: 'Минеральные комплексы',
            categoryId: '375',
            categorySlug: 'mineralnye-kompleksy'
          },
          {
            name: 'Витаминно-минеральные комплексы',
            categoryId: '362',
            categorySlug: 'vitaminno-mineralnye-kompleksy'
          }
        ]
      },
      {
        name: 'Витамины',
        categoryId: '12',
        categorySlug: 'vitaminy',
        items: [
          {
            name: 'Витамин A',
            categoryId: '21',
            categorySlug: 'vitamin-a'
          },
          {
            name: 'Витамин B',
            categoryId: '3',
            categorySlug: 'vitamin-b'
          },
          {
            name: 'Витамин C',
            categoryId: '4',
            categorySlug: 'vitamin-c'
          },
          {
            name: 'Витамин D',
            categoryId: '24',
            categorySlug: 'vitamin-d'
          },
          {
            name: 'Витамин E',
            categoryId: '5',
            categorySlug: 'vitamin-e'
          },
          {
            name: 'Витамин K',
            categoryId: '63',
            categorySlug: 'vitamin-k'
          },
          {
            name: 'Фолиевая кислота (B9)',
            categoryId: '334',
            categorySlug: 'folievaya-kislota-b9'
          },
          {
            name: 'Для детей',
            categoryId: '87',
            categorySlug: 'dlya-detey'
          }
          // {
          //   name: 'Для подростков',
          //   categoryId: null,
          //   categorySlug: null
          // },
          // {
          //   name: 'Для взрослых',
          //   categoryId: null,
          //   categorySlug: null
          // }
        ]
      },
      {
        name: 'Минералы',
        categoryId: '79',
        categorySlug: 'mineraly',
        items: [
          {
            name: 'Кальций',
            categoryId: '178',
            categorySlug: 'kaltsiy'
          },
          {
            name: 'Железо',
            categoryId: '51',
            categorySlug: 'zhelezo'
          },
          {
            name: 'Магний',
            categoryId: '59',
            categorySlug: 'magniy'
          },
          {
            name: 'Калий',
            categoryId: '192',
            categorySlug: 'kaliy'
          },
          {
            name: 'Цинк',
            categoryId: '35',
            categorySlug: 'tsink'
          },
          {
            name: 'Селен',
            categoryId: '60',
            categorySlug: 'selen'
          },
          {
            name: 'Йод',
            categoryId: '37',
            categorySlug: 'yod'
          },
          {
            name: 'Хром',
            categoryId: '36',
            categorySlug: 'hrom'
          }
        ]
      },
      {
        name: 'Для детей',
        categoryId: '87',
        categorySlug: 'dlya-detey',
        items: [
          {
            name: 'Витамин D',
            categoryId: '24',
            categorySlug: 'vitamin-d'
          },
          {
            name: 'Кальций',
            categoryId: '178',
            categorySlug: 'kaltsiy'
          },
          {
            name: 'Цинк',
            categoryId: '35',
            categorySlug: 'tsink'
          },
          {
            name: 'Витамин C',
            categoryId: '4',
            categorySlug: 'vitamin-c'
          }
        ]
      },
      {
        name: 'Женское здоровье',
        categoryId: '123',
        categorySlug: 'zhenskoe-zdorove',
        items: [
          {
            name: 'Молочные железы',
            categoryId: '206',
            categorySlug: 'molochnye-zhelezy'
          },
          {
            name: 'Мультивитамины для женщин',
            categoryId: '243',
            categorySlug: 'multivitaminy-dlya-zhenschin'
          },
          {
            name: 'ПМС (предменструальный синдром)',
            categoryId: '163',
            categorySlug: 'pms-predmenstrualnyy-sindrom'
          },
          {
            name: 'Менопауза',
            categoryId: '197',
            categorySlug: 'menopauza'
          },
          {
            name: 'Беременность',
            categoryId: '105',
            categorySlug: 'beremennost'
          }
        ]
      },
      {
        name: 'Мужское здоровье',
        categoryId: '124',
        categorySlug: 'muzhskoe-zdorove',
        items: [
          {
            name: 'Мультивитамины для мужчин',
            categoryId: '244',
            categorySlug: 'multivitaminy-dlya-muzhchin'
          },
          {
            name: 'Предстательная железа',
            categoryId: '175',
            categorySlug: 'predstatelnaya-zheleza'
          },
          {
            name: 'Эректильная функция',
            categoryId: '245',
            categorySlug: 'erektilnaya-funktsiya'
          },
          {
            name: 'Для будущих пап',
            categoryId: '246',
            categorySlug: 'dlya-buduschih-pap'
          }
        ]
      },
      {
        name: 'Для крепкого сна',
        categoryId: '108',
        categorySlug: 'son',
        items: []
      },
      {
        name: 'Anti-age и долголетие',
        categoryId: '106',
        categorySlug: 'anti-age-i-dolgoletie',
        items: []
      },
      {
        name: 'Иммуностимуляторы, иммуномодуляторы',
        categoryId: '7',
        categorySlug: 'immunostimulyatory-immunomodulyatory',
        items: []
      },
      {
        name: 'Успокаивающие и антистресс',
        categoryId: '247',
        categorySlug: 'uspokaivayuschie-i-antistress',
        items: []
      },
      {
        name: 'Энергия и работоспособность',
        categoryId: '119',
        categorySlug: 'energiya-i-rabotosposobnost',
        items: []
      },
      {
        name: 'Память и внимание',
        categoryId: '133',
        categorySlug: 'pamyat-i-vnimanie',
        items: []
      },
      {
        name: 'Детоксикация',
        categoryId: '111',
        categorySlug: 'detoksikatsiya',
        items: []
      },
      {
        name: 'Иммуностимуляторы, иммуномодуляторы',
        categoryId: '7',
        categorySlug: 'immunostimulyatory-immunomodulyatory',
        items: []
      },
      {
        name: 'От вредных привычек',
        categoryId: '332',
        categorySlug: 'ot-vrednyh-privychek',
        items: []
      },
      {
        name: 'Ароматерапия',
        categoryId: '248',
        categorySlug: 'aromaterapiya',
        items: []
      },
      {
        name: 'Домашняя аптечка',
        categoryId: '249',
        categorySlug: 'domashnyaya-aptechka',
        items: []
      },
      {
        name: 'По системам организма',
        categoryId: '366',
        categorySlug: 'po-sistemam-organizma',
        items: [
          {
            name: 'Гормональная система',
            categoryId: '139',
            categorySlug: 'gormonalnaya-sistema'
          },
          {
            name: 'Кровеносная система',
            categoryId: '125',
            categorySlug: 'krovenosnaya-sistema'
          },
          {
            name: 'Кожа, волосы и ногти',
            categoryId: '113',
            categorySlug: 'volosy-nogti-i-kozha'
          },
          {
            name: 'Микробиота и ЖКТ',
            categoryId: '112',
            categorySlug: 'mikrobiota-i-zhkt'
          },
          {
            name: 'Мочеполовая система',
            categoryId: '126',
            categorySlug: 'mochepolovaya-sistema'
          },
          {
            name: 'Мозг',
            categoryId: '107',
            categorySlug: 'rabota-mozga'
          },
          {
            name: 'Мышечная ткань',
            categoryId: '141',
            categorySlug: 'myshechnaya-tkan'
          },
          {
            name: 'Нервная система',
            categoryId: '109',
            categorySlug: 'nervnaya-sistema'
          },
          {
            name: 'Опорно-двигательный аппарат',
            categoryId: '176',
            categorySlug: 'oporno-dvigatelnyy-apparat'
          },
          {
            name: 'Память и внимание',
            categoryId: '133',
            categorySlug: 'pamyat-i-vnimanie'
          },
          {
            name: 'Печень и желчный',
            categoryId: '134',
            categorySlug: 'pechen-i-zhelchnyy'
          },
          {
            name: 'Почки',
            categoryId: '150',
            categorySlug: 'pochki'
          },
          {
            name: 'Поджелудочная железа',
            categoryId: '151',
            categorySlug: 'podzheludochnaya-zheleza'
          },
          {
            name: 'Предстательная железа',
            categoryId: '175',
            categorySlug: 'predstatelnaya-zheleza'
          },
          {
            name: 'При аллергии',
            categoryId: '101',
            categorySlug: 'pri-allergii'
          },
          {
            name: 'Против ОРВИ',
            categoryId: '188',
            categorySlug: 'protiv-orvi'
          },
          {
            name: 'Репродуктивная система',
            categoryId: '171',
            categorySlug: 'reproduktivnaya-sistema'
          },
          {
            name: 'Щитовидная железа',
            categoryId: '120',
            categorySlug: 'schitovidnaya-zheleza'
          },
          {
            name: 'Сердце и сосуды',
            categoryId: '117',
            categorySlug: 'serdtse-i-sosudy'
          },
          {
            name: 'Снижение сахара и инсулина',
            categoryId: '145',
            categorySlug: 'snizhenie-sahara-i-insulina'
          },
          {
            name: 'Связки и сухожилия',
            categoryId: '162',
            categorySlug: 'svyazki-i-suhozhiliya'
          },
          {
            name: 'Ухо, горло, нос',
            categoryId: '129',
            categorySlug: 'uho-gorlo-nos'
          }
        ]
      }
    ]
  },
  {
    name: 'Спортивное питание',
    categoryId: '372',
    categorySlug: 'sportivnoe-pitanie',
    items: [
      {
        name: 'Протеин',
        categoryId: '347',
        categorySlug: 'protein',
        items: [
          {
            name: 'Сывороточный протеин',
            categoryId: '345',
            categorySlug: 'syvorotochnyy-protein'
          },
          {
            name: 'Изолят',
            categoryId: '250',
            categorySlug: 'izolyat'
          },
          {
            name: 'Растительный протеин',
            categoryId: '48',
            categorySlug: 'rastitelnyy-protein'
          }
        ]
      },
      {
        name: 'Гейнеры',
        categoryId: '344',
        categorySlug: 'geynery',
        items: []
      },
      {
        name: 'Креатин',
        categoryId: '251',
        categorySlug: 'kreatin',
        items: []
      },
      {
        name: 'Аминокислоты',
        categoryId: '30',
        categorySlug: 'aminokisloty',
        items: []
      },
      {
        name: 'L-карнитин',
        categoryId: '73',
        categorySlug: 'l-karnitin',
        items: []
      },
      {
        name: 'BCAA',
        categoryId: '231',
        categorySlug: 'bcaa',
        items: []
      },
      {
        name: 'Цитруллин',
        categoryId: '374',
        categorySlug: 'tsitrullin',
        items: []
      },
      {
        name: 'Предтренировочные комплексы',
        categoryId: '355',
        categorySlug: 'predtrenirovochnye-kompleksy',
        items: []
      },
      {
        name: 'Жиросжигатели',
        categoryId: '252',
        categorySlug: 'zhiroszhigateli',
        items: []
      },
      {
        name: 'Протеиновые батончики',
        categoryId: '253',
        categorySlug: 'proteinovye-batonchiki',
        items: []
      },
      {
        name: 'Напитки',
        categoryId: '182',
        categorySlug: 'napitki',
        items: []
      },
      {
        name: 'Кости и суставы',
        categoryId: '114',
        categorySlug: 'kosti-i-sustavy',
        items: []
      },
      {
        name: 'Связки и сухожилия',
        categoryId: '162',
        categorySlug: 'svyazki-i-suhozhiliya',
        items: []
      }
    ]
  },
  {
    name: 'Здоровое питание',
    categoryId: '373',
    categorySlug: 'zdorovoe-pitanie',
    items: [
      {
        name: 'Функциональные продукты',
        categoryId: '376',
        categorySlug: 'funktsionalnye-produkty',
        items: []
      },
      {
        name: 'Напитки',
        categoryId: '182',
        categorySlug: 'napitki',
        items: [
          {
            name: 'Коктейли',
            categoryId: '360',
            categorySlug: 'kokteyli'
          },
          {
            name: 'Кофе',
            categoryId: '44',
            categorySlug: 'kofe'
          }
        ]
      },
      {
        name: 'Травяные сборы и чаи',
        categoryId: '26',
        categorySlug: 'travyanye-sbory-i-chai',
        items: [
          {
            name: 'При простуде',
            categoryId: '254',
            categorySlug: 'pri-prostude'
          },
          {
            name: 'Для иммунитета',
            categoryId: '255',
            categorySlug: 'dlya-immuniteta'
          },
          {
            name: 'Для сердца и сосудов',
            categoryId: '256',
            categorySlug: 'dlya-serdtsa-i-sosudov'
          },
          {
            name: 'Для ЖКТ',
            categoryId: '102',
            categorySlug: 'dlya-zhkt'
          },
          {
            name: 'Для очищения организма',
            categoryId: '258',
            categorySlug: 'dlya-ochischeniya-organizma'
          },
          {
            name: 'Для похудения и контроля аппетита',
            categoryId: '259',
            categorySlug: 'dlya-pohudeniya-i-kontrolya-appetita'
          },
          {
            name: 'Для печени и почек',
            categoryId: '260',
            categorySlug: 'dlya-pecheni-i-pochek'
          },
          {
            name: 'Для зрения',
            categoryId: '261',
            categorySlug: 'dlya-zreniya'
          },
          {
            name: 'При диабете',
            categoryId: '262',
            categorySlug: 'pri-diabete'
          },
          {
            name: 'Успокаивающее и для сна',
            categoryId: '263',
            categorySlug: 'uspokaivayuschee-i-dlya-sna'
          },
          {
            name: 'Для энергии и работоспособности',
            categoryId: '264',
            categorySlug: 'dlya-energii-i-rabotosposobnosti'
          },
          {
            name: 'Для суставов и связок',
            categoryId: '265',
            categorySlug: 'dlya-sustavov-i-svyazok'
          }
        ]
      },
      {
        name: 'Полезный завтрак',
        categoryId: '266',
        categorySlug: 'poleznyy-zavtrak',
        items: []
      },
      {
        name: 'Сахарозаменители и подсластители',
        categoryId: '358',
        categorySlug: 'saharozameniteli-i-podslastiteli',
        items: []
      },
      {
        name: 'Суперфуды',
        categoryId: '28',
        categorySlug: 'superfudy',
        items: [
          {
            name: 'Спирулина',
            categoryId: '22',
            categorySlug: 'spirulina'
          },
          {
            name: 'Хлорелла',
            categoryId: '17',
            categorySlug: 'hlorella'
          }
        ]
      },
      {
        name: 'Масла, соусы и соль',
        categoryId: '359',
        categorySlug: 'masla-sousy-i-sol',
        items: []
      },
      {
        name: 'Сладости и снеки',
        categoryId: '336',
        categorySlug: 'sladosti-i-sneki',
        items: [
          {
            name: 'Батончики',
            categoryId: '267',
            categorySlug: 'batonchiki'
          },
          {
            name: 'Безлактозный шоколад',
            categoryId: '70',
            categorySlug: 'bezlaktoznyy-shokolad'
          },
          {
            name: 'Конфеты, мармелад',
            categoryId: '268',
            categorySlug: 'konfety-marmelad'
          },
          {
            name: 'Печенье, вафли, выпечка',
            categoryId: '269',
            categorySlug: 'pechene-vafli-vypechka'
          },
          {
            name: 'Пасты и урбечи',
            categoryId: '270',
            categorySlug: 'pasty-i-urbechi'
          },
          {
            name: 'Безглютеновые снеки',
            categoryId: '27',
            categorySlug: 'bezglyutenovye-sneki'
          },
          {
            name: 'Леденцы',
            categoryId: '367',
            categorySlug: 'ledentsy'
          }
        ]
      },
      {
        name: 'Бакалея',
        categoryId: '18',
        categorySlug: 'bakaleya',
        items: [
          {
            name: 'Крупы и мука',
            categoryId: '25',
            categorySlug: 'krupy-i-muka'
          },
          {
            name: 'Гранола',
            categoryId: '82',
            categorySlug: 'granola'
          },
          {
            name: 'Клетчатка',
            categoryId: '41',
            categorySlug: 'kletchatka'
          },
          {
            name: 'Смесь для выпечки',
            categoryId: '271',
            categorySlug: 'smes-dlya-vypechki'
          }
        ]
      },
      {
        name: 'Специализированное питание',
        categoryId: '272',
        categorySlug: 'spetsializirovannoe-pitanie',
        items: []
      }
    ]
  },
  {
    name: 'Грибы',
    categoryId: '156',
    categorySlug: 'griby',
    items: [
      {
        name: 'Чага',
        categoryId: '377',
        categorySlug: 'chaga'
      },
      {
        name: 'Ежовик гребенчатый',
        categoryId: '378',
        categorySlug: 'ezhovik-grebenchatyy'
      },
      {
        name: 'Микродозинг',
        categoryId: '379',
        categorySlug: 'mikrodozing'
      },
      {
        name: 'Шиитаке',
        categoryId: '380',
        categorySlug: 'shiitake'
      },
      {
        name: 'Кордицепс',
        categoryId: '381',
        categorySlug: 'korditseps'
      },
      {
        name: 'Лисички',
        categoryId: '382',
        categorySlug: 'lisichki'
      },
      {
        name: 'Рейши',
        categoryId: '385',
        categorySlug: 'reyshi'
      },
      {
        name: 'Другие грибы',
        categoryId: '383',
        categorySlug: 'drugie-griby'
      }
    ]
  },
  {
    name: 'Косметика и уход',
    categoryId: '209',
    categorySlug: 'kosmetika',
    items: [
      {
        name: 'Российская косметика',
        categoryId: '273',
        categorySlug: 'rossiyskaya-kosmetika',
        items: [
          {
            name: 'Солнцезащитные средства',
            categoryId: '335',
            categorySlug: 'solntsezaschitnye-sredstva'
          },
          {
            name: 'Для волос',
            categoryId: '276',
            categorySlug: 'dlya-volos'
          }
        ]
      },
      {
        name: 'Подарочные наборы',
        categoryId: 'Наборы',
        categorySlug: 'nabory',
        items: []
      },
      {
        name: 'Натуральная косметика',
        categoryId: '277',
        categorySlug: 'naturalnaya-kosmetika',
        items: []
      },
      {
        name: 'Солнцезащитные средства',
        categoryId: '335',
        categorySlug: 'solntsezaschitnye-sredstva',
        items: [
          {
            name: 'Для детей',
            categoryId: '87',
            categorySlug: 'dlya-detey'
          },
          {
            name: 'Средства от ожогов и укусов насекомых',
            categoryId: '349',
            categorySlug: 'sredstva-ot-ozhogov-i-ukusov-nasekomyh'
          }
        ]
      },
      {
        name: 'Уход за лицом',
        categoryId: '278',
        categorySlug: 'uhod-za-litsom',
        items: [
          {
            name: 'Косметические масла',
            categoryId: '369',
            categorySlug: 'kosmeticheskie-masla'
          },
          {
            name: 'Кремы и гели',
            categoryId: '279',
            categorySlug: 'kremy-i-geli'
          },
          {
            name: 'Сыворотки',
            categoryId: '280',
            categorySlug: 'syvorotki'
          },
          {
            name: 'Для области вокруг глаз',
            categoryId: '281',
            categorySlug: 'dlya-oblasti-vokrug-glaz'
          },
          {
            name: 'Для снятия макияжа',
            categoryId: '368',
            categorySlug: 'dlya-snyatiya-makiyazha'
          },
          {
            name: 'Скрабы и пилинги',
            categoryId: '294',
            categorySlug: 'skraby-i-pilingi'
          },
          {
            name: 'Тоники, спреи, гидролаты',
            categoryId: '282',
            categorySlug: 'toniki-sprei-gidrolaty'
          },
          {
            name: 'Маски',
            categoryId: '283',
            categorySlug: 'maski'
          },
          {
            name: 'Аппараты для красоты и здоровья',
            categoryId: '284',
            categorySlug: 'apparaty-dlya-krasoty-i-zdorovya'
          }
        ]
      },
      {
        name: 'Уход за волосами',
        categoryId: '285',
        categorySlug: 'uhod-za-volosami',
        items: [
          {
            name: 'От выпадения волос',
            categoryId: '286',
            categorySlug: 'ot-vypadeniya-volos'
          },
          {
            name: 'Профессиональный уход для волос',
            categoryId: '287',
            categorySlug: 'professionalnyy-uhod-dlya-volos'
          },
          {
            name: 'Шампуни',
            categoryId: '288',
            categorySlug: 'shampuni'
          },
          {
            name: 'Бальзамы, кондиционеры, сыворотки',
            categoryId: '289',
            categorySlug: 'balzamy-konditsionery-syvorotki'
          },
          {
            name: 'Маски для волос',
            categoryId: '290',
            categorySlug: 'maski-dlya-volos'
          },
          {
            name: 'Уход за кожей головы',
            categoryId: '291',
            categorySlug: 'uhod-za-kozhey-golovy'
          }
        ]
      },
      {
        name: 'Уход за телом',
        categoryId: '292',
        categorySlug: 'uhod-za-telom',
        items: [
          {
            name: 'Увлажнение и питание',
            categoryId: '348',
            categorySlug: 'uvlazhnenie-i-pitanie'
          },
          {
            name: 'Средства для душа',
            categoryId: '293',
            categorySlug: 'sredstva-dlya-dusha'
          },
          {
            name: 'Скрабы и пилинги',
            categoryId: '294',
            categorySlug: 'skraby-i-pilingi'
          },
          {
            name: 'Средства для ванной',
            categoryId: '295',
            categorySlug: 'sredstva-dlya-vannoy'
          },
          {
            name: 'Для массажа',
            categoryId: '296',
            categorySlug: 'dlya-massazha'
          },
          {
            name: 'Уход за руками и ногтями',
            categoryId: '297',
            categorySlug: 'uhod-za-rukami-i-nogtyami'
          },
          {
            name: 'Уход за ногами',
            categoryId: '298',
            categorySlug: 'uhod-za-nogami'
          },
          {
            name: 'Аксессуары',
            categoryId: '189',
            categorySlug: 'aksessuary'
          }
        ]
      }
    ]
  },
  {
    name: 'Гигиена',
    categoryId: '184',
    categorySlug: 'gigiena',
    items: [
      {
        name: 'Гигиена полости рта',
        categoryId: '185',
        categorySlug: 'rotovaya-polost-i-zuby',
        items: [
          {
            name: 'Зубные пасты и порошки',
            categoryId: '299',
            categorySlug: 'zubnye-pasty-i-poroshki'
          }
        ]
      },
      {
        name: 'Личная гигиена женщин',
        categoryId: '300',
        categorySlug: 'lichnaya-gigiena-zhenschin',
        items: [
          {
            name: 'Дезодоранты, антиперспиранты',
            categoryId: '301',
            categorySlug: 'dezodoranty-antiperspiranty'
          }
        ]
      },
      {
        name: 'Дезодоранты, антиперспиранты',
        categoryId: '301',
        categorySlug: 'dezodoranty-antiperspiranty',
        items: []
      },
      {
        name: 'Средства для и после бритья',
        categoryId: '302',
        categorySlug: 'sredstva-dlya-i-posle-britya',
        items: []
      },
      {
        name: 'Сексуальное здоровье',
        categoryId: '303',
        categorySlug: 'seksualnoe-zdorove',
        items: [
          {
            name: 'Лубриканты',
            categoryId: '304',
            categorySlug: 'lubrikanty'
          },
          {
            name: 'Игры 18+',
            categoryId: '305',
            categorySlug: 'igry-18'
          },
          {
            name: 'Наборы для взрослых',
            categoryId: '306',
            categorySlug: 'nabory-dlya-vzroslyh'
          }
        ]
      }
    ]
  },
  {
    name: 'Для детей и мам',
    categoryId: '307',
    categorySlug: 'dlya-detey-i-mam',
    items: [
      {
        name: 'Подгузники, салфетки',
        categoryId: '350',
        categorySlug: 'podguzniki-salfetki',
        items: [
          {
            name: 'Подгузники',
            categoryId: '308',
            categorySlug: 'podguzniki'
          },
          {
            name: 'Детские влажные салфетки',
            categoryId: '309',
            categorySlug: 'detskie-vlazhnye-salfetki'
          },
          {
            name: 'Детские ватные палочки',
            categoryId: '351',
            categorySlug: 'detskie-vatnye-palochki'
          }
        ]
      },
      {
        name: 'Детская косметика и гигиена',
        categoryId: '310',
        categorySlug: 'detskaya-kosmetika-i-gigiena',
        items: [
          {
            name: 'Гигиенические помадки',
            categoryId: '352',
            categorySlug: 'detskie-balzamy-dlya-gub'
          },
          {
            name: 'Детские зубные пасты',
            categoryId: '311',
            categorySlug: 'detskie-zubnye-pasty'
          },
          {
            name: 'Детские кремы, масло',
            categoryId: '353',
            categorySlug: 'detskie-kremy-losyony-maslo'
          },
          {
            name: 'Детские кремы от солнца',
            categoryId: '354',
            categorySlug: 'detskie-solntsezaschitnye-sredstva'
          },
          {
            name: 'Детские средства для купания',
            categoryId: '312',
            categorySlug: 'detskie-sredstva-dlya-kupaniya'
          },
          {
            name: 'Детские шампуни',
            categoryId: '313',
            categorySlug: 'detskie-shampuni'
          }
        ]
      },
      {
        name: 'Для беременных и кормящих мам',
        categoryId: '105',
        categorySlug: 'beremennost',
        items: []
      }
    ]
  },
  {
    name: 'Бытовая нехимия',
    categoryId: '193',
    categorySlug: 'bytovaya-nehimiya',
    items: [
      {
        name: 'Чистящие и моющие средства',
        categoryId: '314',
        categorySlug: 'chistyaschie-i-moyuschie-sredstva',
        items: [
          {
            name: 'Для пола, ковров и мебели',
            categoryId: '315',
            categorySlug: 'dlya-pola-kovrov-i-mebeli'
          },
          {
            name: 'Для посуды',
            categoryId: '316',
            categorySlug: 'dlya-posudy'
          },
          {
            name: 'Для овощей, фруктов',
            categoryId: '317',
            categorySlug: 'dlya-ovoschey-fruktov'
          },
          {
            name: 'Для туалета и ванны',
            categoryId: '318',
            categorySlug: 'dlya-tualeta-i-vanny'
          },
          {
            name: 'Для кухни',
            categoryId: '319',
            categorySlug: 'dlya-kuhni'
          },
          {
            name: 'Для плит и духовок',
            categoryId: '320',
            categorySlug: 'dlya-plit-i-duhovok'
          },
          {
            name: 'Для посудомоечных машин',
            categoryId: '321',
            categorySlug: 'dlya-posudomoechnyh-mashin'
          },
          {
            name: 'Для стекол и зеркал',
            categoryId: '322',
            categorySlug: 'dlya-stekol-i-zerkal'
          },
          {
            name: 'Универсальные и специализированные',
            categoryId: '323',
            categorySlug: 'universalnye-i-spetsializirovannye'
          }
        ]
      },
      {
        name: 'Стирка и уход за бельем',
        categoryId: '324',
        categorySlug: 'stirka-i-uhod-za-belem',
        items: [
          {
            name: 'Средства для стирки',
            categoryId: '325',
            categorySlug: 'sredstva-dlya-stirki'
          },
          {
            name: 'Кондиционеры и ополаскиватели',
            categoryId: '326',
            categorySlug: 'konditsionery-i-opolaskivateli'
          },
          {
            name: 'Хозяйственное мыло',
            categoryId: '327',
            categorySlug: 'hozyaystvennoe-mylo'
          },
          {
            name: 'Отбеливатели и пятновыводители',
            categoryId: '328',
            categorySlug: 'otbelivateli-i-pyatnovyvoditeli'
          }
        ]
      },
      {
        name: 'Антисептические средства',
        categoryId: '329',
        categorySlug: 'antisepticheskie-sredstva',
        items: []
      }
    ]
  },
  {
    name: 'Для домашних питомцев',
    categoryId: '179',
    categorySlug: 'dlya-domashnih-pitomtsev',
    items: [
      {
        name: 'Натуральные корма',
        categoryId: '330',
        categorySlug: 'naturalnye-korma',
        items: [
          {
            name: 'Корм для собак',
            categoryId: '370',
            categorySlug: 'korm-dlya-sobak'
          },
          {
            name: 'Корм для кошек',
            categoryId: '371',
            categorySlug: 'korm-dlya-koshek'
          }
        ]
      },
      {
        name: 'Лакомства для собак',
        categoryId: '356',
        categorySlug: 'lakomstva-dlya-sobak',
        items: []
      },
      {
        name: 'Добавки для животных',
        categoryId: '357',
        categorySlug: 'dobavki-dlya-zhivotnyh',
        items: [
          {
            name: 'БАД',
            categoryId: '80',
            categorySlug: 'bad'
          }
        ]
      },
      {
        name: 'Косметика для животных',
        categoryId: '331',
        categorySlug: 'kosmetika-dlya-zhivotnyh',
        items: []
      }
    ]
  }
] as NewCategoryMenuItem[]
