<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    iconName: string
    iconClass?: object | string | null
    text?: string | null
    withHover?: boolean
  }>(),
  { iconClass: null, text: null, withHover: false }
)

const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>

<template>
  <div class="button-with-badge">
    <SvgIcon
      class="cursor-pointer"
      :class="[iconClass, { 'with-hover': withHover }]"
      :name="iconName"
      @click="emit('click')"
    />

    <div
      v-if="text !== null"
      class="button-with-badge__badge absolute flex items-center justify-center whitespace-nowrap text-white"
    >
      {{ text }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

.button-with-badge {
  display: inline-block;
  position: relative;

  svg {
    font-size: 24px;
    transition: color .3s ease;

    &.with-hover:hover {
      color: variables.$main-green-color;
    }
  }

  &__badge {
    background: variables.$main-green-color;
    border-radius: 8px;
    font-size: 11px;
    line-height: 11px;
    min-width: 17px;
    padding: 3px;
    pointer-events: none;
    right: 0;
    top: 0;
    transform: translate(50%, -25%);
  }
}
</style>
