<script lang="ts" setup>
import NewCatalogMenuBox from '~/components/new-catalog-menu/NewCatalogMenuBox.vue'
import NewCatalogMenuLink from '~/components/new-catalog-menu/NewCatalogMenuLink.vue'
import type { NewCategoryMenuItem } from '~/types/menus'

defineProps<{
  items: NewCategoryMenuItem[]
}>()

const activeItemIndex = ref(0)

defineExpose({
  activeItemIndex
})
</script>

<template>
  <div class="column-4 tb12">
    <NewCatalogMenuBox>
      <ul>
        <li v-for="(x, i) in items" :key="i" @mouseover="activeItemIndex = i">
          <NewCatalogMenuLink
            class="new-catalog-menu-link flex cursor-pointer items-center justify-between"
            :class="{ 'new-catalog-menu-link_active': activeItemIndex === i }"
            :data="x"
            icon-name="outlined/navigate-next"
          />
        </li>
      </ul>
    </NewCatalogMenuBox>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:color';

@use 'assets/css/variables';

.new-catalog-menu-link {
  border-radius: 12px;
  box-sizing: border-box;
  color: variables.$main-gray-color;
  padding: 10px 16px;
  text-decoration: none;
  transition: all .25s ease;
  width: 100%;

  &:hover,
  &_active {
    background: color.mix(variables.$main-gray-color, white, 10%);
    color: variables.$main-green-color;
  }

  &:not([href]) {
    color: variables.$main-red-color;
  }
}
</style>
