<template>
  <div class="new-catalog-menu-box">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.new-catalog-menu-box {
  box-sizing: border-box;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 24px 0;
}
</style>
