<script lang="ts" setup>
import { storeToRefs } from 'pinia'

import Page from '~/enums/page'
import { useAuthStore } from '~/store/auth'
import { useUserStore } from '~/store/user'
import type { HeaderMainMenuItem } from '~/types/menus'

const route = useRoute()
const authStore = useAuthStore()
const userStore = useUserStore()

const { hasAccessToAdminPanel, isSpecialist, isSupplier } = storeToRefs(userStore)

const props = withDefaults(
  defineProps<{
    isMobile?: boolean
  }>(),
  { isMobile: false }
)

const emit = defineEmits<{
  (e: 'open', path: string, isAuthRequired: boolean): void
}>()

const additionalItems: HeaderMainMenuItem[] = [
  { isAuthRequired: false, name: 'О нас', path: Page.About },
  // { isAuthRequired: false, name: 'Мы в офлайне', path: Page.Offline },
  { isAuthRequired: false, name: 'Оплата', path: Page.Payment },
  { isAuthRequired: false, name: 'Доставка', path: Page.Delivery },
  { hasSeparator: true, isAuthRequired: false, name: 'Возврат', path: Page.Return },
  { isAuthRequired: false, name: 'Специалисты', path: Page.Specialists },
  { isAuthRequired: false, name: 'Программа лояльности для специалистов', path: Page.Partner },
  { isAuthRequired: false, name: 'Оптовым клиентам', path: Page.Distributor },
  { hasSeparator: true, isAuthRequired: false, name: 'Поставщикам', path: Page.Supplier }
]

const items = computed(() => {
  const items: HeaderMainMenuItem[] = [
    { isAuthRequired: false, name: 'Каталог', path: Page.Products },
    { isAuthRequired: false, name: 'Акции', path: Page.SpecialOffers },
    { isAuthRequired: false, name: 'Подбор БАД', path: Page.Tests },
    { isAuthRequired: false, name: 'Статьи', path: Page.Articles }
  ]

  if (props.isMobile) {
    items.push(...additionalItems)

    items.push({ isAuthRequired: true, name: authStore.isUser ? 'ЛК клиента' : 'Вход/регистрация', path: Page.Account })

    if (authStore.isUser) {
      if (isSpecialist.value) {
        items.push({ isAuthRequired: true, name: 'ЛК специалиста', path: Page.Specialist })
      }

      if (isSupplier.value) {
        items.push({ isAuthRequired: true, name: 'ЛК поставщика', path: Page.Supplier })
      }

      if (hasAccessToAdminPanel.value) {
        items.push({ isAuthRequired: true, name: 'Админпанель', path: Page.Admin })
      }
    }
  }

  return items
})
</script>

<template>
  <ul class="header-main-menu flex" :class="{ mobile: isMobile, usual: !isMobile }">
    <li v-for="x in items" :key="x.path" :class="{ 'has-separator': Boolean(x.hasSeparator) }">
      <a
        class="whitespace-nowrap"
        :class="{ 'nuxt-link-exact-active': x.path === route.path }"
        :data-text="x.name"
        :href="x.path"
        @click.prevent="emit('open', x.path, x.isAuthRequired)"
      >
        {{ x.name }}
      </a>
    </li>

    <li v-if="!isMobile">
      <DropdownMenu icon-name="outlined/more-horiz" width="250px">
        <DropdownMenuItem
          v-for="x in additionalItems"
          :key="x.path"
          class="more-menu-item"
          :has-separator="Boolean(x.hasSeparator)"
          :path="x.path"
          @click="emit('open', x.path, x.isAuthRequired)"
        >
          {{ x.name }}
        </DropdownMenuItem>
      </DropdownMenu>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@use 'sass:color';

@use 'assets/css/variables';

.header-main-menu {
  font-size: 18px;

  &.mobile {
    flex-direction: column;
    margin-left: 24px;
    margin-top: 16px;

    & > li {
      margin: 0 8px 12px 0;

      &:last-child {
        margin-bottom: 0;
      }

      &.has-separator:not(:last-child):after {
        background: color.mix(variables.$main-gray-color, white, 20%);
        content: '';
        display: block;
        height: 1px;
        margin: 12px 0 0;
      }

      a {
        line-height: 24px;
      }
    }
  }

  &.usual {
    height: 100%;

    @media screen and (max-width: 1070px) {
      display: none;
    }

    & > li {
      align-items: center;
      display: flex;

      & > a {
        align-items: center;
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: column;
        line-height: 20px;
        padding: 20px 0;

        &:before {
          content: attr(data-text);
          font-weight: 500;
          height: 0;
          overflow: hidden;
          pointer-events: none;
          user-select: none;
          visibility: hidden;
        }

        &:hover {
          font-weight: 500;
        }

        &.nuxt-link-exact-active {
          border-bottom: 2px solid variables.$main-green-color;
          font-weight: 500;
          padding-bottom: 18px;
        }
      }

      svg {
        font-size: 24px;
        cursor: pointer;

        &:hover {
          color: variables.$main-green-color;
        }
      }
    }
  }

  & > li {
    margin-left: 24px;

    & > a {
      color: inherit;
      text-decoration: none;

      &:hover,
      &.nuxt-link-exact-active {
        color: variables.$main-green-color;
      }
    }
  }

  .more-menu-item {
    font-size: 18px;
  }
}
</style>
