import { useModal } from 'vue-final-modal'

import WorldShoppingDay from '~/components/modal-windows/WorldShoppingDay.vue'

const localStorageKey = 'worldShoppingDayModalWindowClosedAt'
const delayBeforeShowingInSeconds = 30

export function useWorldShoppingDayModalWindow () {
  function getWorldShoppingDayModalWindowClosedAt (): number | null {
    const value = localStorage.getItem(localStorageKey)

    return value === null ? null : Number(value)
  }

  function getCurrentDayNumber (): number | null {
    switch ((new Date()).toISOString().slice(0, 10)) { // "YYYY-MM-DD" format.
      case '2024-11-14':
        return 1
      case '2024-11-15':
        return 2
      case '2024-11-16':
        return 3
      case '2024-11-17':
        return 4
      default:
        return null
    }
  }

  function makeWorldShoppingDayModalWindowClosed (): void {
    localStorage.setItem(localStorageKey, JSON.stringify(Date.now()))
  }

  function needToShowWorldShoppingDayModalWindow (): boolean {
    return getWorldShoppingDayModalWindowClosedAt() === null
  }

  function showWorldShoppingDayModalWindowIfNeeded (): void {
    const currentDayNumber = getCurrentDayNumber()

    if (currentDayNumber === null || !needToShowWorldShoppingDayModalWindow()) {
      return
    }

    setTimeout(
      async function (): Promise<void> {
        if (!needToShowWorldShoppingDayModalWindow()) {
          return
        }

        await useModal({ component: WorldShoppingDay }).open()
      },
      1000 * delayBeforeShowingInSeconds
    )
  }

  return {
    getWorldShoppingDayModalWindowClosedAt,
    makeWorldShoppingDayModalWindowClosed,
    showWorldShoppingDayModalWindowIfNeeded
  }
}
